import React, {useState, useRef} from 'react'
import { MailIcon, PhoneIcon, AtSymbolIcon } from '@heroicons/react/outline'


const Form = () => {

  const formRef = useRef(null)
  const scriptUrl = "https://script.google.com/macros/s/AKfycbwa7TCAPQGKjEA9o1I4B0zh9bpsr_YcXqgOE-oBqia2Gv4HkuVWhQZHLGS86wDjej4K/exec"
  const [loading, setLoading] = useState(false)

  const handleSubmit = (e) =>{
      e.preventDefault()
      setLoading(true)

      fetch(scriptUrl, {
      method: 'POST', 
      body: new FormData(formRef.current),

  }).then(res => {
          //console.log("SUCCESSFULLY SUBMITTED")
          window.location.assign('/contact-validation/')
          setLoading(false)
      })
      .catch(err => console.log(err))
  }


  return (
    <div className="relative bg-white">
      <div className="absolute inset-0">
        <div className="absolute inset-y-0 left-0 w-1/2 bg-gray-50" />
      </div>
      <div className="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
        

        <div className="bg-gray-50 py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12 hidden lg:block">
          <div className="max-w-lg mx-auto">
            <h1 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">Compétences et proximité</h1>
            <p className="mt-3 text-lg leading-6 text-gray-500">
              Expertises indépendante des fissures, études de sol G5, travaux de reprise de fondations, réparation des fissures...
            </p>
            <p className="mt-3 text-lg leading-6 text-gray-500">
            Nos équipes internes et partenaires interviennent dans différents types de contexte fissuration :
Avis avant achat immobilier, garantie dommage-ouvrage et décennale, garantie des catastrophes naturelles sécheresse, litige amiable ou judiciaire
            </p>
            <p className="mt-3 text-lg leading-6 text-gray-500">
            Intervention partout en France
Obtenez l’aide dans les plus brefs délais en remplissant le formulaire.
            </p>
            <dl className="mt-8 text-base text-gray-500">
              <div>
                <dt className="sr-only">En cas d'urgence, nous contacter au :</dt>
              </div>
              <div className="mt-6">
                <dt className="sr-only">Téléphone</dt>
                <dd className="flex">
                  <PhoneIcon className="flex-shrink-0 h-6 w-6 text-gray-400" aria-hidden="true" />
                  <span className="ml-3">06 63 86 19 84</span>
                </dd>
              </div>
              <div className="mt-3">
                <dt className="sr-only">Email</dt>
                <dd className="flex">
                  <MailIcon className="flex-shrink-0 h-6 w-6 text-gray-400" aria-hidden="true" />
                  <span className="ml-3">contact<AtSymbolIcon  className="h-5 inline" />expertise-secheresse.fr</span>
                </dd>
              </div>
            </dl>
          </div>
        </div>
        <div className="bg-white py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
          <div className="max-w-lg mx-auto lg:max-w-none">
            <form 
              className="grid grid-cols-1 gap-y-6"
              ref={formRef} onSubmit={handleSubmit}
            >
              <div>
                <label htmlFor="last-name" className="sr-only">
                  Nom
                </label>
                <input
                  required
                  type="text"
                  name="Nom"
                  id="last-name"
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-sky-500 focus:border-sky-500 border-gray-300 rounded-md"
                  placeholder="Nom"
                />
              </div>
              <div>
                <label htmlFor="first-name" className="sr-only">
                  Prénom
                </label>
                <input
                  required
                  type="text"
                  name="Prénom"
                  id="first-name"
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-sky-500 focus:border-sky-500 border-gray-300 rounded-md"
                  placeholder="Prénom"
                />
              </div>
              <div>
                <label htmlFor="first-name" className="sr-only">
                  Code postal
                </label>
                <input
                  required
                  type="text"
                  name="Code Postal"
                  id="cp"
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-sky-500 focus:border-sky-500 border-gray-300 rounded-md"
                  placeholder="Code postal"
                />
              </div>
              <div>
                <label htmlFor="email" className="sr-only">
                  Email
                </label>
                <input
                  required
                  id="email"
                  name="Email"
                  type="email"
                  autoComplete="email"
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-sky-500 focus:border-sky-500 border-gray-300 rounded-md"
                  placeholder="Email"
                />
              </div>
              <div>
                <label htmlFor="phone" className="sr-only">
                  Téléphone
                </label>
                <input
                  required
                  type="text"
                  name="Téléphone"
                  id="phone"
                  autoComplete="tel"
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-sky-500 focus:border-sky-500 border-gray-300 rounded-md"
                  placeholder="Téléphone"
                />
              </div>
              <div>
                <label htmlFor="message" className="sr-only">
                  Message
                </label>
                <textarea
                  required
                  id="message"
                  name="Message"
                  rows={4}
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-sky-500 focus:border-sky-500 border border-gray-300 rounded-md"
                  placeholder="Message"
                  defaultValue={''}
                />
              </div>
              {/* <div>
                  <input 
                      type="checkbox" 
                      id="mise_en_relation" 
                      name="Mise en relation" 
                      className="inline mr-2 shadow-sm py-1 px-3 placeholder-gray-500 focus:ring-emerald-500 focus:border-emerald-500 border-gray-300 rounded-md" 
                      required
                  />J'accepte que mes données soient analysées par les équipes de Expertise-Fissuration.fr et ses partenaires pour répondre à ma demande
              </div> */}
              <div>
                <label htmlFor="message" className="sr-only">
                  Message
                </label>
                <input 
                    type="checkbox" 
                    id="cgu" 
                    name="CGU" 
                    className="inline mr-3 shadow-sm py-1 px-3 placeholder-gray-500 focus:ring-sky-500 focus:border-sky-500 border-gray-300 rounded-md" 
                    required 
                /> J'accepte les <a href="/legal/cgu/" className="text-sky-500" target="_blank" rel="noreferrer">Conditions Générales d'Utilisation</a>
              </div>
              <div className="hidden">
                <label htmlFor="tellepro" className="sr-only">
                  Téllépro
                </label>
                <input
                  type="text"
                  name="tellepro"
                  id="tellepro"
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-sky-500 focus:border-sky-500 border-gray-300 rounded-md"
                  placeholder="Téllépro"
                />
                <input
                  type="text"
                  name="Source"
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-sky-500 focus:border-sky-500 border-gray-300 rounded-md"
                  placeholder="Source"
                  value="Expertise-Secheresse.fr"
                />
                <input
                  type="text"
                  name="Moyen"
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-sky-500 focus:border-sky-500 border-gray-300 rounded-md"
                  placeholder="Moyen"
                  value="Formulaire Expertise-Secheresse Contact"
                />
                <input
                  type="text"
                  name="Formulaire"
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-sky-500 focus:border-sky-500 border-gray-300 rounded-md"
                  placeholder="Formulaire"
                  value="Expertise-Secheresse"
                />
              </div>
              <div>
                <button
                  type="submit"
                  className="inline-flex justify-center py-3 px-6 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-sky-600 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
                >
                  {loading ? "Envoi en cours ..." : "Envoyer"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Form